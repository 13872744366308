import { Button, Card, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCourse } from '../../http/api/course';
import { errorToast } from '../../utils/component/toast/style';
import { CourseDetail } from '../../utils/type';
import { Lessones } from '../Lesson/Lessones';
import './Course.scss';

export function Course() {
  const location = useLocation();
  const path = location.pathname;
  const courseId = parseInt(path.split('/')[2]);
  const navigate = useNavigate();
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);

  const [course, setCourse] = useState<CourseDetail>();

  useEffect(() => {
    const fetchCourse = async () => {
      const result = await getCourse(courseId);
      if (result) {
        setCourse(result);
        const orders = result.lessons.map((lesson) => lesson.order);
        const checkArray = new Set(orders);
        if (checkArray.size !== orders.length) {
          setIsDuplicated(true);
        }
      } else {
        toast.error('コースと授業の取得に失敗しました。', errorToast);
      }
    };
    fetchCourse();
  }, [courseId]);

  const onEdit = () => {
    navigate(`/course/edit/${courseId}`);
  };

  return (
    <div className='course-detail'>
      <h1>コース詳細</h1>
      <div className='button-line'>
        <Button onClick={onEdit}>編集</Button>
      </div>
      <Card className='course' loading={!course}>
        <p>ID：{course?.id}</p>
        <p>タイトル：{course?.title}</p>
        <p>説明：{course?.description}</p>
        <p>stripePriceId：{course?.stripe_config.stripe_price_id}</p>
      </Card>
      <Divider />
      {isDuplicated && (
        <div className='duplicate-caution'>
          順番が重複しています。順番は重複がないように登録してください
        </div>
      )}

      <Lessones lessons={course?.lessons} />
    </div>
  );
}
