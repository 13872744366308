import React from 'react';
import './NewPromotion.scss';
import { Button, Form, Input } from 'antd';
import { postPromotion } from '../../../http/api/promotion';
import { useNavigate } from 'react-router-dom';
import { PROMOTION_PATH } from '../../../routes/routes';
import { toast } from 'react-toastify';

interface PromotionFormPayload {
  name: string;
}

export function NewPromotion() {
  const [form] = Form.useForm<PromotionFormPayload>();
  const navigate = useNavigate();
  const onSubmit = async (value: PromotionFormPayload) => {
    const { name } = value;
    const response = await postPromotion({ items: { name: name } });
    if (response) {
      toast.success('プロモーションの作成に成功しました');
      navigate(PROMOTION_PATH);
    } else {
      toast.error('プロモーションの作成に失敗しました');
    }
  };
  return (
    <div className='new-promotions'>
      <h1>プロモーションの作成</h1>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item rules={[{ required: true }]} name={'name'}>
          <Input type='text' placeholder='プロモーション名' />
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit' type='primary'>
            プロモーションを作成する
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
