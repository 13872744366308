import React, { useEffect, useState } from 'react';
import './LessonDetail.scss';
import { LessonVideos } from '../LessonVideo/LessonVideos';
import { Card, Divider, Spin } from 'antd';
import { Lesson } from '../../utils/type';
import { useParams } from 'react-router-dom';
import { getLesson } from '../../http/api/lesson';
import { toast } from 'react-toastify';

export function LessonDetail() {
  const params = useParams();
  const lessonId = params.lessonId ? params.lessonId : '0';
  const [lesson, setLesson] = useState<Lesson>();

  useEffect(() => {
    const fetchLesson = async () => {
      const response = await getLesson(parseInt(lessonId));
      if (response) {
        setLesson(response);
      } else {
        toast.error('授業の取得に失敗しました');
      }
    };
    fetchLesson();
  }, [lessonId]);

  return (
    <Spin spinning={!lesson}>
      <div className='lesson-detail'>
        <h1>授業詳細</h1>
        <Card className='course' loading={!lesson}>
          <p>第{lesson?.order}回</p>
          <p>タイトル：{lesson?.title}</p>
          <p>説明：{lesson?.description}</p>
        </Card>
        <Divider />
        <div className='lesson'></div>
        <LessonVideos lessonVideos={lesson?.lesson_videos} />
      </div>
    </Spin>
  );
}
