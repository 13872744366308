export const USER_SIGNIN_PATH = '/signin';

export const ROOT_PATH = '/';

export const HOME_PATH = '/home';

export const USER_PATH = '/user';

export const COURSE_PATH = '/course';
export const ADD_COURSE_PATH = '/course/new';
export const COURSE_DETAIL_PATH = `/course/:courseId`;
export const COURSE_EDIT_PATH = `/course/edit/:courseId`;

export const NEW_LESSON_PATH = `${COURSE_DETAIL_PATH}/lesson/new`;
export const LESSON_DETAIL_PATH = `/lesson/:lessonId`;
export const LESSON_EDIT_PATH = `/lesson/:lessonId/edit`;
export const NEW_LESSON_VIDEO = `/lesson/:lessonId/video/new`;
export const EDIT_LESSON_VIDEO = `/lesson_video/:videoId/edit`;
export const LESSON_VIDEO_DETAIL = `/lesson_video/:videoId`;

export const INQUIRE_PATH = '/inquire';

export const INFLUENCER_PATH = '/influencer';
export const INFLUENCER_ADD_PATH = '/influencer/new';
export const EDIT_INFLUENCER_PATH = `/influencer/:influencerId/edit`;

export const PLAN_PATH = `/plan`;
export const NEW_PLAN_PATH = `/plan/new`;
export const PLAN_DETAIL_PATH = `/plan/:planId`;
export const PLAN_EDIT_PATH = `/plan/:planId/edit`;

export const PROMOTION_PATH = `/promotions`;
export const NEW_PROMOTION_PATH = `/promotion/new`;
export const PROMOTION_DETAIL_PATH = `/promotion/:promotionId`;
export const PROMOTION_EDIT_PATH = `/promotion/:promotionId/edit`;
