import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getInfluencers } from '../../http/api/influencer';
import { INFLUENCER_ADD_PATH } from '../../routes/routes';
import { errorToast } from '../../utils/component/toast/style';
import { InfluencerBase } from '../../utils/type';
import './Influencer.scss';

interface TableDataType {
  key: React.Key;
  icon_image_url: JSX.Element;
  id: number;
  name: string;
  edit: JSX.Element;
}

export function Influencer() {
  const navigate = useNavigate();
  const [influencers, setInfluencers] = useState<InfluencerBase[]>();
  useEffect(() => {
    const fetchInfluencers = async () => {
      const result = await getInfluencers();
      if (result) {
        setInfluencers(result);
      } else {
        toast.error('インフルエンサーの取得に失敗しました', errorToast);
      }
    };
    fetchInfluencers();
  }, []);

  const handleEditButton = useCallback(
    (influencerId: number) => {
      navigate(`/influencer/${influencerId}/edit`);
    },
    [navigate]
  );

  const dataSourse = useMemo(() => {
    const source: TableDataType[] = [];
    influencers?.forEach((influencer) => {
      source.push({
        key: influencer.label,
        icon_image_url: <img src={influencer.icon_image_url} alt='' className='icon_img' />,
        id: influencer.id,
        name: influencer.name,
        edit: <Button onClick={() => handleEditButton(influencer.id)}>編集</Button>,
      });
    });
    return source;
  }, [influencers, handleEditButton]);

  const columns: ColumnsType<TableDataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '画像',
      dataIndex: 'icon_image_url',
      key: 'icon_image_url',
    },
    {
      title: '名前',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '編集',
      dataIndex: 'edit',
      key: 'edit',
    },
  ];

  const handleAddButton = () => {
    navigate(INFLUENCER_ADD_PATH);
  };

  return (
    <div className='influencer'>
      <h1>インフルエンサー管理</h1>
      <div className='button-line'>
        <Button type='primary' onClick={handleAddButton}>
          新規追加
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSourse}
        style={{ width: 800, margin: '10px auto' }}
      ></Table>
    </div>
  );
}
