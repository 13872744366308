import { PromotionBase, PromotionCode, PromotionDetail } from '../../utils/type';
import {
  GET_PROMOTION,
  GET_PROMOTIONS,
  PATCH_PROMOTION,
  PATCH_PROMOTION_CODE_ACTIVATE,
  PATCH_PROMOTION_CODE_DEACTIVATE,
  POST_PROMOTION,
  POST_PROMOTION_CODE,
} from '../endpoints';
import { ReadResponse, RequestData, getJson, patchJson, postJson } from '../network';

type GetPromotionsResponse = ReadResponse<PromotionBase[]>;
export async function getPromotions() {
  const path = GET_PROMOTIONS;
  const response = await getJson<GetPromotionsResponse>(path);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type PostPromotionResponse = ReadResponse<PromotionDetail[]>;

type PostPromotionRequest = RequestData<{ name: string }>;

export async function postPromotion(data: PostPromotionRequest) {
  const path = POST_PROMOTION;
  const response = await postJson<PostPromotionResponse>(path, data);
  if (response.status === 'ok') {
    return true;
  } else {
    return false;
  }
}

type GetPromotionRespoonse = ReadResponse<PromotionDetail>;

export async function getPromotion(promotionId: number) {
  const path = GET_PROMOTION(promotionId);
  const response = await getJson<GetPromotionRespoonse>(path);
  console.log(response);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type PatchPromotionResponse = ReadResponse<PromotionDetail>;

type PatchPromotionRequest = RequestData<{
  name: string;
  promotion_courses: {
    course_id: number;
    order: number;
    thumb_image: File | string | undefined;
  }[];
}>;

export async function patchPromotion(promotionId: number, data: PatchPromotionRequest) {
  const path = PATCH_PROMOTION(promotionId);
  const response = await patchJson<PatchPromotionResponse>(path, data);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type PostPromotionCodeResponse = ReadResponse<PromotionDetail>;

type PostPromotionCodeRequest = RequestData<{
  name: string;
}>;

export async function postPromotionCode(promotionId: number, data: PostPromotionCodeRequest) {
  const path = POST_PROMOTION_CODE(promotionId);
  const response = await postJson<PostPromotionCodeResponse>(path, data);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type PatchPromotionCodeActivateResponse = ReadResponse<PromotionCode>;

export async function patchPromotionCodeActivate(
  promotionId: number,
  codeId: number,
  afterStatus: boolean
) {
  const path = afterStatus
    ? PATCH_PROMOTION_CODE_ACTIVATE(codeId)
    : PATCH_PROMOTION_CODE_DEACTIVATE(codeId);

  const response = await postJson<PatchPromotionCodeActivateResponse>(path);

  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}
