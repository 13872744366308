import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getLessonVideo } from '../../http/api/lessonVideo';
import { errorToast } from '../../utils/component/toast/style';
import { LessonVideo } from '../../utils/type';

export function LessonVideoDetail() {
  const params = useParams();
  const videoId = params.videoId ? params.videoId : '0';
  const [lessonVideo, setLessonVideo] = useState<LessonVideo>();

  useEffect(() => {
    const fetchLessonVideo = async () => {
      const response = await getLessonVideo(parseInt(videoId));
      if (response) {
        setLessonVideo(response);
      } else {
        toast.error('動画の取得に失敗しました', errorToast);
      }
    };
    fetchLessonVideo();
  }, [videoId]);
  return (
    <div className='lesson-video-detail'>
      <h1>動画詳細</h1>
      <Card className='course' loading={!lessonVideo}>
        <p>第{lessonVideo?.order}回</p>
        <p>タイトル：{lessonVideo?.title}</p>
        <p>説明：{lessonVideo?.description}</p>
        <iframe title='vimeo-player' src={lessonVideo?.video_url} width='870' height='490'></iframe>
      </Card>
    </div>
  );
}
