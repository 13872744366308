import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LessonVideo } from '../../utils/type';
import './LessonVideos.scss';

interface TableDataType {
  key: React.Key;
  id: number;
  title: string;
  video_url: string;
  order: number;
  show: ReactNode;
  edit: ReactNode;
}

interface Props {
  lessonVideos: LessonVideo[] | undefined;
}

export function LessonVideos({ lessonVideos }: Props) {
  const navigate = useNavigate();
  const params = useParams();
  const lessonId = params.lessonId;

  const toNewVideo = () => {
    navigate(`/lesson/${lessonId}/video/new`);
  };

  const handleEditButton = useCallback(
    (id: number) => {
      navigate(`/lesson_video/${id}/edit`);
    },
    [navigate]
  );

  const handleShowButton = useCallback(
    (id: number) => {
      navigate(`/lesson_video/${id}`);
    },
    [navigate]
  );

  const data = useMemo(() => {
    const source: TableDataType[] = [];
    if (lessonVideos) {
      lessonVideos.forEach((video) => {
        source.push({
          key: video.id,
          id: video.id,
          title: video.title,
          video_url: video.video_url,
          order: video.order,
          show: <Button onClick={() => handleShowButton(video.id)}>詳細</Button>,
          edit: <Button onClick={() => handleEditButton(video.id)}>編集</Button>,
        });
      });
    }
    return source;
  }, [lessonVideos, handleEditButton, handleShowButton]);

  const columns: ColumnsType<TableDataType> = [
    {
      title: '順番',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '動画',
      dataIndex: 'video_url',
      key: 'video_url',
    },
    {
      title: '詳細',
      dataIndex: 'show',
      key: 'show',
    },
    {
      title: '編集',
      dataIndex: 'edit',
      key: 'edit',
    },
  ];

  return (
    <div className='lesson-videos'>
      <h1>この授業の動画一覧</h1>
      <div className='button-line'>
        <Button type='primary' onClick={toNewVideo}>
          新規追加
        </Button>
      </div>
      <Table columns={columns} dataSource={data} size='small'></Table>
    </div>
  );
}
