import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Lesson } from '../../utils/type';
import './Lessones.scss';

interface TableDataType {
  key: React.Key;
  id: number;
  order: number;
  title: string;
  edit: ReactNode;
  show: ReactNode;
}

interface Props {
  lessons?: Lesson[];
}

export function Lessones({ lessons }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleEditButton = useCallback(
    (id: number, lesson: Lesson) => {
      navigate(`/lesson/${id}/edit`, { state: lesson });
    },
    [navigate]
  );

  const handleShowButton = useCallback(
    (id: number, lesson: Lesson) => {
      navigate(`/lesson/${id}`, { state: lesson });
    },
    [navigate]
  );

  const data = useMemo(() => {
    const source: TableDataType[] = [];

    if (lessons) {
      lessons.forEach((lesson) => {
        source.push({
          key: lesson.id,
          id: lesson.id,
          title: lesson.title,
          order: lesson.order,
          edit: <Button onClick={() => handleEditButton(lesson.id, lesson)}>編集</Button>,
          show: <Button onClick={() => handleShowButton(lesson.id, lesson)}>詳細</Button>,
        });
      });
    }

    return source;
  }, [lessons, handleEditButton, handleShowButton]);

  const columns: ColumnsType<TableDataType> = [
    {
      title: '順番',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '詳細',
      dataIndex: 'show',
      key: 'show',
    },
    {
      title: '編集',
      dataIndex: 'edit',
      key: 'edit',
    },
  ];
  const handleAddButton = () => {
    navigate(`${location.pathname}/lesson/new`);
  };

  return (
    <div className='lessons'>
      <h1>このコースの授業一覧</h1>
      <div className='button-line'>
        <Button onClick={handleAddButton}>新規追加</Button>
      </div>
      <Table columns={columns} dataSource={data} loading={!lessons}></Table>
    </div>
  );
}
