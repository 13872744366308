import { Button, Switch, Table } from 'antd';
import './Courses.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ADD_COURSE_PATH } from '../../routes/routes';
import { CourseBase } from '../../utils/type';
import { closeCourse, getCourses, openCourse } from '../../http/api/course';
import { toast } from 'react-toastify';
import { errorToast } from '../../utils/component/toast/style';
import { ColumnsType } from 'antd/es/table';

interface TableDataType {
  key: React.Key;
  id: number;
  title: string;
  influencer: string;
  show: JSX.Element;
  edit: JSX.Element;
  active: JSX.Element;
}
export function Courses() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState<CourseBase[]>();
  useEffect(() => {
    const fetchCourses = async () => {
      const result = await getCourses();
      if (result) {
        setCourses(result);
      } else {
        toast.error('コースの取得に失敗しました', errorToast);
      }
    };
    fetchCourses();
  }, []);

  const switchCourse = async (value: boolean, courseId: number) => {
    const response = value === true ? await openCourse(courseId) : await closeCourse(courseId);
    if (response) {
      response.is_opened
        ? toast.success('コースを公開しました')
        : toast.success('コースを非公開にしました');
    } else {
      toast.error('コースのステータス切り替えに失敗しました');
    }
  };

  const columns: ColumnsType<TableDataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '講座名',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'インフルエンサー',
      dataIndex: 'influencer',
      key: 'influencer',
    },
    {
      title: '詳細',
      dataIndex: 'show',
      key: 'show',
    },
    {
      title: '編集',
      dataIndex: 'edit',
      key: 'edit',
    },
    {
      title: '公開',
      dataIndex: 'active',
      key: 'active',
    },
  ];

  const handleShowButton = useCallback(
    (id: number) => {
      navigate(`/course/${id}`);
    },
    [navigate]
  );

  const handleEditButton = useCallback(
    (id: number) => {
      navigate(`/course/edit/${id}`);
    },
    [navigate]
  );

  const dataSourse = useMemo(() => {
    const source: TableDataType[] = [];
    courses?.forEach((course) => {
      source.push({
        key: course.id,
        id: course.id,
        title: course.title,
        influencer: course.influencer.name,
        show: <Button onClick={() => handleShowButton(course.id)}>詳細</Button>,
        edit: <Button onClick={() => handleEditButton(course.id)}>編集</Button>,
        active: (
          <Switch
            onChange={(value) => switchCourse(value, course.id)}
            defaultChecked={course.is_opened}
          ></Switch>
        ),
      });
    });
    return source;
  }, [courses, handleEditButton, handleShowButton]);

  const handleAddButton = () => {
    navigate(ADD_COURSE_PATH);
  };

  return (
    <div className='course'>
      <h1>コース管理</h1>
      <div className='button-line'>
        <Button onClick={handleAddButton}>新規追加</Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSourse}
        style={{ width: 800, margin: '0 auto' }}
      ></Table>
    </div>
  );
}
