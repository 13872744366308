import { Button, Card, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getLessonVideo, updateLessonVideo } from '../../http/api/lessonVideo';
import { errorToast } from '../../utils/component/toast/style';
import { LessonVideo } from '../../utils/type';

interface LessonVideoUpdatePayload {
  vimeo_id: string;
  title: string;
  description: string;
  order: number;
}

export function EditLessonVideo() {
  const params = useParams();
  const videoId = params.videoId ? params.videoId : '0';
  const [lessonVideo, setLessonVideo] = useState<LessonVideo>();
  const [form] = Form.useForm<LessonVideoUpdatePayload>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLessonVideo = async () => {
      const response = await getLessonVideo(parseInt(videoId));
      if (response) {
        setLessonVideo(response);
      } else {
        toast.error('動画の取得に失敗しました', errorToast);
      }
    };
    fetchLessonVideo();
  }, [videoId]);

  const onSubmit = async (value: LessonVideoUpdatePayload) => {
    const response = await updateLessonVideo({ items: value }, parseInt(videoId));
    if (response) {
      navigate(`/lesson_video/${response.id}`);
      toast.success('授業動画を更新しました');
    } else {
      toast.error('授業動画の更新に失敗しました');
    }
  };

  return (
    <div className='edit-lesson-video'>
      <h1>授業動画の編集</h1>
      <Card loading={!lessonVideo}>
        <Form form={form} onFinish={onSubmit}>
          <Form.Item name='title' label={'タイトル'} labelCol={{ span: 5 }}>
            <Input type='text' defaultValue={lessonVideo?.title} />
          </Form.Item>
          <Form.Item
            name='description'
            initialValue={lessonVideo?.description}
            label={'詳細情報'}
            labelCol={{ span: 5 }}
          >
            <Input type='text' defaultValue={lessonVideo?.description} />
          </Form.Item>
          <Form.Item
            name='vimeo_id'
            initialValue={lessonVideo?.vimeo_id}
            label={'URL'}
            labelCol={{ span: 5 }}
          >
            <Input type='text' defaultValue={lessonVideo?.vimeo_id} />
          </Form.Item>
          <Form.Item
            name='order'
            initialValue={lessonVideo?.order}
            label={'順番'}
            labelCol={{ span: 5 }}
          >
            <Input type='number' defaultValue={lessonVideo?.order} />
          </Form.Item>
          <Form.Item>
            <Button htmlType='submit' type='primary'>
              編集完了
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
