import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialAdminUserState } from './initializes';
import { AdminUserState } from './type';

const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState: initialAdminUserState,
  reducers: {
    setAdminUser: (state: AdminUserState, action: PayloadAction<AdminUserState>) => {
      return action.payload;
    },
  },
});

export const { setAdminUser } = adminUserSlice.actions;

export const adminUser = adminUserSlice.reducer;
