import { UserBase } from '../../utils/type';
import { GET_USERS } from '../endpoints';
import { getJson, ReadResponse } from '../network';

type GetUserResponse = ReadResponse<UserBase[]>;

export async function getUsers() {
  const path = GET_USERS;
  const resposne = await getJson<GetUserResponse>(path);
  if (resposne.status === 'ok') {
    return resposne.data;
  } else {
    return false;
  }
}
