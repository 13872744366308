import { Button, Card, Form, Input } from 'antd';
import './EditCourse.scss';
import React, { useEffect, useRef, useState } from 'react';
import { CourseDetail } from '../../utils/type';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCourse, updateCourse } from '../../http/api/course';
import { toast } from 'react-toastify';
import { errorToast } from '../../utils/component/toast/style';
import { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import { useImageSave } from '../../utils/component/cropper/useImageSave';
import { TrimmingModal } from '../../utils/component/cropper/TrimmingModal';
import ReactCrop from 'react-image-crop';

interface UpdateCoursePayload {
  stripe_price_id: string;
  title: string;
  description: string;
  price: number;
  thumb_image: string | File;
}

export function EditCourse() {
  const location = useLocation();
  const path = location.pathname;
  const courseId = parseInt(path.split('/')[3]);
  const [course, setCourse] = useState<CourseDetail>();
  const [form] = Form.useForm<UpdateCoursePayload>();
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [saveCroppedImage] = useImageSave(setIsOpen, imgSrc);
  const imgRef = useRef<HTMLImageElement>(null);

  const finishCrop = (crop: PixelCrop, percentCrop: PercentCrop) => {
    if (!imgRef.current) {
      return;
    }
    const canvas = document.createElement('canvas');
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL('image/jpeg');

    setCroppedImage(base64Image);
  };

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || '');
          setIsOpen(true);
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  useEffect(() => {
    const fetchCourse = async () => {
      const result = await getCourse(courseId);
      if (result) {
        setCourse(result);
      } else {
        toast.error('コースと授業の取得に失敗しました。', errorToast);
      }
    };
    fetchCourse();
  }, [courseId]);

  const onSubmit = async (value: UpdateCoursePayload) => {
    const { title, description, stripe_price_id, price } = value;

    const thumb_image = croppedImage ? croppedImage : course?.thumb_image_url;

    const result = await updateCourse(courseId, {
      items: { title, description, stripe_price_id, thumb_image, price },
    });
    if (!result) {
      toast.error('編集に失敗しました', errorToast);
    } else {
      toast.success('編集に成功しました');
      navigate(`/course/${courseId}`);
    }
  };

  return (
    <div className='course-edit'>
      <h1>コース編集</h1>
      <Card className='course' loading={!course}>
        <Form form={form} onFinish={onSubmit}>
          <Form.Item name='title' initialValue={course?.title}>
            <Input type='text' />
          </Form.Item>
          <Form.Item name='description' initialValue={course?.description}>
            <Input type='text' />
          </Form.Item>
          <Form.Item name='price' initialValue={course?.stripe_config.price}>
            <Input type='number' />
          </Form.Item>
          <Form.Item name='stripe_price_id' initialValue={course?.stripe_config.stripe_price_id}>
            <Input type='text' />
          </Form.Item>
          <Form.Item label='プランのサムネイル' name='main_image' labelCol={{ span: 5 }}>
            <Input type='file' accept='image/*' onChange={onSelectFile}></Input>
          </Form.Item>
          <Form.Item label='画像のプレビュー' name='main_image' labelCol={{ span: 5 }}>
            <img src={croppedImage ? croppedImage : course?.thumb_image_url} alt='' />
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            完了
          </Button>
        </Form>
        <TrimmingModal isOpen={isOpen} setIsOpen={setIsOpen} saveCroppedImg={saveCroppedImage}>
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            aspect={9 / 5}
            onComplete={finishCrop}
          >
            <img src={imgSrc} ref={imgRef} alt='' />
          </ReactCrop>
        </TrimmingModal>
      </Card>
    </div>
  );
}
