import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { errorToast } from '../toast/style';

export const useImageSave = (setShow: Dispatch<SetStateAction<boolean>>, imageInput: any) => {
  const saveCroppedImg = async () => {
    try {
    } catch (err) {
      toast.error('画像処理時にエラーが発生しました', errorToast);
    }

    setShow(false);
  };

  return [saveCroppedImg];
};
