import { Button, Form, Input } from 'antd';
import './AddInfluencer.scss';
import React, { useRef, useState } from 'react';
import { createInfluencer } from '../../http/api/influencer';
import { useNavigate } from 'react-router-dom';
import { INFLUENCER_PATH } from '../../routes/routes';
import { toast } from 'react-toastify';
import { errorToast } from '../../utils/component/toast/style';
import { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import { useImageSave } from '../../utils/component/cropper/useImageSave';
import { TrimmingModal } from '../../utils/component/cropper/TrimmingModal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';

export interface InfluencerCreationPayload {
  name: string;
  icon_img: File;
  label: string;
}

export function AddInfluencer() {
  const navigate = useNavigate();
  const imgRef = useRef<HTMLImageElement>(null);
  const [form] = Form.useForm<InfluencerCreationPayload>();
  const [imgSrc, setImgSrc] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [saveCroppedImage] = useImageSave(setIsOpen, imgSrc);

  const onSubmit = async () => {
    const { name, label, email } = form.getFieldsValue(['name', 'label', 'email']);
    const file = croppedImage;
    if (!name || !file || !email) {
      toast.error('フォームの入力が完成していません');
      return;
    }
    // 画像追加しないといけない
    const result = await createInfluencer({
      items: { name: name, icon_image: file, label: label, email: email },
    });
    if (result) {
      navigate(INFLUENCER_PATH);
    } else {
      toast.error('インフルエンサーの作成に失敗しました', errorToast);
    }
  };

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || '');
          setIsOpen(true);
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const finishCrop = (crop: PixelCrop, percentCrop: PercentCrop) => {
    if (!imgRef.current) {
      return;
    }
    const canvas = document.createElement('canvas');
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL('image/jpeg');

    setCroppedImage(base64Image);
  };

  return (
    <div className='new-influencer'>
      <h1>インフルエンサーの新規作成</h1>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name='name' className='form-line'>
          <Input type='text' placeholder='インフルエンサー名' />
        </Form.Item>
        <Form.Item name='label' className='form-line'>
          <Input type='text' placeholder='URLに埋め込む名前' />
        </Form.Item>
        <Form.Item name='email' className='form-line'>
          <Input type='email' placeholder='example@example.com' />
        </Form.Item>
        <Form.Item name='icon_img'>
          <Input type='file' accept='image/*' onChange={onSelectFile} />
        </Form.Item>
        <Form.Item className='form-line'>
          <img src={croppedImage} alt='' />
        </Form.Item>
        <div className='button-line'>
          <Button type='primary' htmlType='submit'>
            新規追加
          </Button>
        </div>
      </Form>
      <TrimmingModal isOpen={isOpen} setIsOpen={setIsOpen} saveCroppedImg={saveCroppedImage}>
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          aspect={1}
          circularCrop={true}
          onComplete={finishCrop}
        >
          <img src={imgSrc} ref={imgRef} alt='' />
        </ReactCrop>
      </TrimmingModal>
    </div>
  );
}
