export const GET_CSRF_TOKEN = `/api/csrf_token`;

export const BASE_URL = `/api/admin`;

export const ADMIN_SIGNIN = `${BASE_URL}/signin`;
export const GET_ADMIN_ME = `${BASE_URL}/admin_users/me`;

export const GET_USERS = `${BASE_URL}/users`;

export const CREATE_INFLUENCER = `${BASE_URL}/influencers`;
export const GET_COURSES = `${BASE_URL}/courses`;
export const CREATE_COURSE = (influencerId: number) =>
  `${BASE_URL}/influencers/${influencerId}/courses`;
export const GET_COURSE = (courseId: number) => `${BASE_URL}/courses/${courseId}`;
export const UPDATE_COURSE = (courseId: number) => `${BASE_URL}/courses/${courseId}`;

export const CREATE_LESSON = (courseId: number) => `${BASE_URL}/courses/${courseId}/lessons`;
export const UPDATE_LESSON = (lessonId: number) => `${BASE_URL}/lessons/${lessonId}`;
export const GET_LESSON = (lessonId: number) => `${BASE_URL}/lessons/${lessonId}`;

export const GET_INFLUENCERS = `${BASE_URL}/influencers`;
export const UPDATE_INFLUENCER = (influencerId: number) =>
  `${BASE_URL}/influencers/${influencerId}`;
export const GET_INFLUENCER = (influencerId: number) => `${BASE_URL}/influencers/${influencerId}`;

export const GET_PLANS = `${BASE_URL}/plans`;
export const GET_PLAN_DETAIL = (planId: number) => `${BASE_URL}/plans/${planId}`;
export const CREATE_PLAN = `${BASE_URL}/plan`;
export const UPDATE_PLAN = (planId: number) => `${BASE_URL}/plans/${planId}`;

export const POST_CREATE_CAMPAIGNS = (planId: number) => `${BASE_URL}/plans/${planId}/campaigns`;
export const POST_ACTIVATE_CAMPAIGNS = (campaignId: number) =>
  `${BASE_URL}/campaigns/${campaignId}/activate`;
export const POST_DEACTIVATE_CAMPAIGNS = (campaignId: number) =>
  `${BASE_URL}/campaigns/${campaignId}/deactivate`;

export const ACTIVE_COURSE = (courseId: number) => `${BASE_URL}/courses/${courseId}/open`;
export const CLOSE_COURSE = (courseId: number) => `${BASE_URL}/courses/${courseId}/close`;

export const CREATE_VIDEO = (lessonId: number) => `${BASE_URL}/lessons/${lessonId}/lesson_videos`;
export const UPDATE_VIDEO = (lessonVideoId: number) => `${BASE_URL}/lesson_videos/${lessonVideoId}`;
export const GET_LESSON_VIDEO = (lessonvideoId: number) =>
  `${BASE_URL}/lesson_videos/${lessonvideoId}`;

export const GET_PROMOTIONS = `${BASE_URL}/promotions`;
export const POST_PROMOTION = `${BASE_URL}/promotions`;
export const GET_PROMOTION = (promotionId: number) => `${BASE_URL}/promotions/${promotionId}`;
export const PATCH_PROMOTION = (promotionId: number) => `${BASE_URL}/promotions/${promotionId}`;
export const POST_PROMOTION_CODE = (promotionId: number) =>
  `${BASE_URL}/promotions/${promotionId}/promotion_codes`;
export const PATCH_PROMOTION_CODE_ACTIVATE = (codeId: number) =>
  `${BASE_URL}/promotion_codes/${codeId}//activate`;
export const PATCH_PROMOTION_CODE_DEACTIVATE = (codeId: number) =>
  `${BASE_URL}/promotion_codes/${codeId}/deactivate`;
