import React, { useEffect, useState } from 'react';
import { PromotionDetail } from '../../../utils/type';
import { useParams } from 'react-router-dom';
import { getPromotion, postPromotionCode } from '../../../http/api/promotion';
import { toast } from 'react-toastify';
import { Button, Card, Divider, Form, Input, Spin } from 'antd';
import { PromotionCodes } from './PromotionCodes/PromotionCodes';
import { errorToast } from '../../../utils/component/toast/style';
import './Promotion.scss';

interface CreatePromotionCodePayload {
  name: string;
}

export function Promotion() {
  const [promotion, setPromotion] = useState<PromotionDetail>();
  const params = useParams();
  const promotionId = params.promotionId ? params.promotionId : '0';
  const [form] = Form.useForm<CreatePromotionCodePayload>();
  useEffect(() => {
    const fetchPromotion = async () => {
      const response = await getPromotion(parseInt(promotionId));
      console.log(response);
      if (response) {
        setPromotion(response);
      } else {
        toast.error('プロモーションの取得に失敗しました');
      }
    };
    fetchPromotion();
  }, [promotionId]);

  const onSubmit = async (value: CreatePromotionCodePayload) => {
    const { name } = value;
    const response = await postPromotionCode(parseInt(promotionId), { items: { name: name } });
    if (!response) {
      toast.error('プロモーションコードの作成に失敗しました', errorToast);
      return;
    }
    toast.success('プロモーションコードの作成に成功しました');

    setPromotion(response);
  };
  return (
    <Spin spinning={!promotion}>
      <h1>プロモーション詳細</h1>
      <Card loading={!promotion} className='promotion-detail'>
        <p>プロモーション名：{promotion?.name}</p>
        {promotion?.promotion_courses.map((pc) => (
          <>
            <p>
              無料開講：{pc.course.title}の第{pc.order}回まで
            </p>
            <img src={pc.thumb_image_url} alt={pc.course.title} />
          </>
        ))}
        <Divider />
        <Form form={form} onFinish={onSubmit} className='code-form'>
          <Form.Item name='name' className='input-field'>
            <Input type='text' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              作成
            </Button>
          </Form.Item>
        </Form>
        <PromotionCodes promotionId={parseInt(promotionId)} codes={promotion?.codes} />
      </Card>
    </Spin>
  );
}
