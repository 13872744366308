import { LessonVideo } from '../../utils/type';
import { CREATE_VIDEO, GET_LESSON_VIDEO, UPDATE_VIDEO } from '../endpoints';
import { getJson, patchJson, postJson, ReadResponse, RequestData } from '../network';

type CreateLessonVideoRequest = RequestData<{
  vimeo_id: string;
  title: string;
  description: string;
}>;

type CreateLessonVideoResponse = ReadResponse<LessonVideo>;

export async function creaetLessonVideo(lessonId: number, data: CreateLessonVideoRequest) {
  const path = CREATE_VIDEO(lessonId);
  const response = await postJson<CreateLessonVideoResponse>(path, data);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type GetLessonVideoResponse = ReadResponse<LessonVideo>;

export async function getLessonVideo(lessonVideoId: number) {
  const path = GET_LESSON_VIDEO(lessonVideoId);
  const response = await getJson<GetLessonVideoResponse>(path);
  if (response) {
    return response.data;
  } else {
    return false;
  }
}

type UpdateLessonVideoRequest = RequestData<{
  vimeo_id: string;
  title: string;
  description: string;
  order: number;
}>;

type UpdateLessonVideoResponse = ReadResponse<LessonVideo>;

export async function updateLessonVideo(data: UpdateLessonVideoRequest, videoId: number) {
  const path = UPDATE_VIDEO(videoId);
  const response = await patchJson<UpdateLessonVideoResponse>(path, data);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}
