import React from 'react';
import './Home.scss';

export function Home() {
  return (
    <div className='home'>
      <h1>ホーム画面</h1>
    </div>
  );
}
