import { GET_CSRF_TOKEN } from './endpoints';

export type ReadResponse<T = Record<string, any> | any[] | any> = {
  data: T;
  status: string;
  code: number;
};

export type RequestData<T = Record<string, any>> = {
  items: T;
};

type ReadCsrfTokenResponse = ReadResponse<{
  'X-CSRF-Token': string;
}>;

export let csrfToken: string | undefined;

export let defaultOption: RequestInit = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export async function request<T>(url: string, options?: RequestInit) {
  const backendProxy = process.env.REACT_APP_API_BASE_HOST;
  console.log('バックエンドプロキシ, backendProxy');
  if (!backendProxy) {
    throw new Error('backednProxy is undefined');
  }
  const request: RequestInit = {
    credentials: 'include',
    mode: 'cors',
    ...defaultOption,
    headers: {
      ...defaultOption.headers,
      ...options?.headers,
    },
    ...options,
  };

  const res = await fetch(`${backendProxy}${url}`, request);

  const response: T = await res.json();

  return response;
}

export async function getJson<T>(path: string, params?: Record<string, any>) {
  const query = (params && `?${new URLSearchParams(params).toString()}`) ?? '';
  const response = await request<T>(path + query, { method: 'GET' });
  return response;
}

export async function postJson<T>(path: string, body: Record<string, any> = {}) {
  const response = await request<T>(path, { method: 'POST', body: JSON.stringify(body) });
  return response;
}

export async function patchJson<T>(path: string, body: Record<string, any> = {}) {
  const response = await request<T>(path, { method: 'PATCH', body: JSON.stringify(body) });
  return response;
}

export async function refreshCsrfToken(): Promise<string | null> {
  const response = await getJson<ReadCsrfTokenResponse>(GET_CSRF_TOKEN);
  if (response.status === 'ok') {
    const csrfToken = response.data['X-CSRF-Token'];
    return csrfToken;
  } else {
    throw new Error('Error when fetch csrf Token');
  }
}

export async function setCsrfTokenForHeader(newCsrfToken: string) {
  csrfToken = newCsrfToken;
  defaultOption = {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
  };
}
