import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { getPlans } from '../../http/api/plan';
import { errorToast } from '../../utils/component/toast/style';
import './Plans.scss';
import { Plan } from '../../utils/type';
import { useNavigate } from 'react-router-dom';
import { NEW_PLAN_PATH } from '../../routes/routes';

interface TableDataType {
  key: React.Key;
  id: number;
  name: string;
  edit: JSX.Element;
  show: JSX.Element;
}

export function Plans() {
  const [plans, setPlans] = useState<Plan[]>();
  const navigate = useNavigate();

  const columns: ColumnsType<TableDataType> = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'プラン名', dataIndex: 'name', key: 'name' },
    { title: '詳細', dataIndex: 'show', key: 'show' },
    { title: '編集', dataIndex: 'edit', key: 'edit' },
  ];

  const handleEditButton = useCallback(
    (id: number) => {
      navigate(`/plan/${id}/edit`);
    },
    [navigate]
  );

  const handleShowButton = useCallback(
    (id: number) => {
      navigate(`/plan/${id}`);
    },
    [navigate]
  );

  const handleAddButton = () => {
    navigate(NEW_PLAN_PATH);
  };

  useEffect(() => {
    const fetchPlans = async () => {
      const result = await getPlans();
      if (result) {
        setPlans(result);
      } else {
        toast.error('プランを取得できませんでした', errorToast);
      }
    };

    fetchPlans();
  }, []);

  const dataSource = useMemo(() => {
    const source: TableDataType[] = [];
    plans?.forEach((plan) => {
      source.push({
        key: plan.id,
        id: plan.id,
        name: plan.name,
        show: <Button onClick={() => handleShowButton(plan.id)}>詳細</Button>,
        edit: <Button onClick={() => handleEditButton(plan.id)}>編集</Button>,
      });
    });
    return source;
  }, [plans, handleEditButton, handleShowButton]);

  return (
    <div className='plans'>
      <h1>プラン管理</h1>
      <div className='button-line'>
        <Button onClick={handleAddButton}>新規追加</Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        style={{ width: 800, margin: '0 auto' }}
      ></Table>
    </div>
  );
}
