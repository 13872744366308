import { Influencer, InfluencerBase, InfluencerDetail } from '../../utils/type';
import {
  CREATE_INFLUENCER,
  GET_INFLUENCER,
  GET_INFLUENCERS,
  UPDATE_INFLUENCER,
} from '../endpoints';
import { getJson, patchJson, postJson, ReadResponse, RequestData } from '../network';

type CreateInfluencerRequest = RequestData<{
  name: string;
  icon_image: File;
  label: string;
  email: string;
}>;

type CreateInfluencerResponse = ReadResponse<{
  influencer: Influencer;
}>;

export async function createInfluencer(data: CreateInfluencerRequest) {
  const response = await postJson<CreateInfluencerResponse>(CREATE_INFLUENCER, data);
  if (response.status === 'ok') {
    return true;
  } else {
    return false;
  }
}

type GetInfluencersResponse = ReadResponse<InfluencerBase[]>;

export async function getInfluencers() {
  const response = await getJson<GetInfluencersResponse>(GET_INFLUENCERS);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type UpdateInfluencerRequest = RequestData<{
  name: string;
  icon_image: File | string;
  label: string;
}>;

type UpdateInfluencerResponse = ReadResponse<Influencer>;

export async function updateInfluencer(influencerId: number, data: UpdateInfluencerRequest) {
  const path = UPDATE_INFLUENCER(influencerId);
  const response = await patchJson<UpdateInfluencerResponse>(path, data);

  if (response.status === 'ok') {
    return true;
  } else {
    return false;
  }
}

type GetInfluencerResponse = ReadResponse<InfluencerDetail>;

export async function getInfluencer(influencerId: number) {
  const path = GET_INFLUENCER(influencerId);
  const response = await getJson<GetInfluencerResponse>(path);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}
