import { Button, Card, Divider, Form, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createPlanCampaign, getPlan } from '../../http/api/plan';
import { errorToast } from '../../utils/component/toast/style';
import { Plan } from '../../utils/type';
import './PlanDetail.scss';
import { Campaigns } from './Campaigns/Campaigns';

interface CreateCampaignPayload {
  name: string;
}

export function PlanDetail() {
  const [plan, setPlan] = useState<Plan>();
  const location = useLocation();
  const pathNameArray = location.pathname.split('/');
  const planId = pathNameArray[pathNameArray.length - 1];
  const [form] = Form.useForm<CreateCampaignPayload>();

  useEffect(() => {
    const fetchPlan = async () => {
      const result = await getPlan(parseInt(planId));
      if (result) {
        setPlan(result);
      } else {
        toast.error('プランの取得に失敗しました', errorToast);
      }
    };
    fetchPlan();
  }, [planId]);

  const onSubmit = async (value: CreateCampaignPayload) => {
    const { name } = value;
    if (!plan || !name) {
      return;
    }
    const result = await createPlanCampaign(plan.id, { items: { name } });
    if (!result) {
      toast.error('キャンペーンの作成に失敗しました', errorToast);
    } else {
      toast.success('キャンペーンの作成に成功しました');
      setPlan({ ...plan, campaigns: [...plan.campaigns, result] });
    }
  };

  return (
    <Spin spinning={!plan}>
      <Card className='plan-detail'>
        <p>プラン名：{plan?.name}</p>
        <p>料金：{plan?.stripe_config.price}</p>
        <p>stripePriceId：{plan?.stripe_config.stripe_price_id}</p>
        <Divider />
        <Form form={form} className='campaign-form' onFinish={onSubmit}>
          <Form.Item name='name' className='input-field'>
            <Input type='text' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              作成
            </Button>
          </Form.Item>
        </Form>
        <Campaigns plan={plan} />
      </Card>
    </Spin>
  );
}
