import React from 'react';
import { Button, Card, Form, Input } from 'antd';
import './SignIn.scss';
import { signIn } from '../../http/api/admin';
import { toast } from 'react-toastify';
import { errorToast } from '../../utils/component/toast/style';
import { useAppDispatch } from '../../store/store';
import { setAdminUser } from '../../store/adminUser/slice';
import { useNavigate } from 'react-router-dom';
import { HOME_PATH } from '../../routes/routes';

interface AdminSignupPayload {
  email: string;
  password: string;
}

export function SignIn() {
  const [form] = Form.useForm<AdminSignupPayload>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSignUp = async () => {
    const { email, password } = form.getFieldsValue(['email', 'password']);
    const result = await signIn({ items: { email: email, password: password } });
    if (result) {
      dispatch(setAdminUser(result));
      navigate(HOME_PATH);
    } else {
      toast.error('ログインに失敗しました', errorToast);
    }
  };
  return (
    <div className='sign-in'>
      <h1>ログイン</h1>
      <Card className='card'>
        <Form form={form} onFinish={handleSignUp}>
          <p>メールアドレス</p>
          <Form.Item name='email' className='input-line'>
            <Input type='text' />
          </Form.Item>
          <p>パスワード</p>
          <Form.Item name='password' className='input-line'>
            <Input type='password' />
          </Form.Item>
          <Form.Item className='button-line'>
            <Button htmlType='submit' type='primary'>
              ログイン
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
