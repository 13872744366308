import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AddCourse } from '../pages/Course/AddCourse';
import { Courses } from '../pages/Course/Courses';
import { Course } from '../pages/Course/Course';
import { Home } from '../pages/Home/Home';
import { AddInfluencer } from '../pages/Influencer/AddInfluencer';
import { Influencer } from '../pages/Influencer/Influencer';
import { Inquire } from '../pages/Inquire/Inquire';
import { LessonDetail } from '../pages/Lesson/LessonDetail';
import { NewLesson } from '../pages/Lesson/NewLesson';
import { SignIn } from '../pages/SignIn/SignIn';
import { User } from '../pages/User/User';
import { AuthedLayout } from './AuthedLayout';
import * as routes from './routes';
import { UnAuthedLayout } from './UnAuthedLayout';
import { Plans } from '../pages/Plan/Plans';
import { PlanDetail } from '../pages/Plan/PlanDetail';
import { NewPlan } from '../pages/Plan/NewPlan';
import { EditCourse } from '../pages/Course/EditCourse';
import { EditPlan } from '../pages/Plan/EditPlan';
import { LessonEdit } from '../pages/Lesson/LessonEdit';
import { NewLessonVideo } from '../pages/LessonVideo/NewLessonVideos';
import { EditLessonVideo } from '../pages/LessonVideo/EditLessonVideo';
import { LessonVideoDetail } from '../pages/LessonVideo/LessonVideoDetail';
import { EditInfluencer } from '../pages/Influencer/EditInfluencer';
import { Promotions } from '../pages/Promotion/Promotions/Promotions';
import { NewPromotion } from '../pages/Promotion/NewPromotion/NewPromotion';
import { Promotion } from '../pages/Promotion/PromotionDetail/Promotion';
import { EditPromotion } from '../pages/Promotion/EditPromotion/EditPromotion';

export function Router() {
  return (
    <Routes>
      <>
        <Route
          path={routes.USER_SIGNIN_PATH}
          element={<UnAuthedLayout component={<SignIn />} />}
        ></Route>
        <Route path={routes.HOME_PATH} element={<AuthedLayout component={<Home />} />}></Route>
        <Route path={routes.ROOT_PATH} element={<AuthedLayout component={<Home />} />}></Route>
        <Route path={routes.COURSE_PATH} element={<AuthedLayout component={<Courses />} />}></Route>
        <Route path={routes.USER_PATH} element={<AuthedLayout component={<User />} />}></Route>
        <Route
          path={routes.INQUIRE_PATH}
          element={<AuthedLayout component={<Inquire />} />}
        ></Route>
        <Route
          path={routes.ADD_COURSE_PATH}
          element={<AuthedLayout component={<AddCourse />} />}
        ></Route>
        <Route
          path={routes.INFLUENCER_PATH}
          element={<AuthedLayout component={<Influencer />} />}
        ></Route>
        <Route
          path={routes.INFLUENCER_ADD_PATH}
          element={<AuthedLayout component={<AddInfluencer />} />}
        ></Route>
        <Route
          path={routes.COURSE_DETAIL_PATH}
          element={<AuthedLayout component={<Course />} />}
        ></Route>
        <Route
          path={routes.COURSE_EDIT_PATH}
          element={<AuthedLayout component={<EditCourse />} />}
        ></Route>
        <Route
          path={routes.NEW_LESSON_PATH}
          element={<AuthedLayout component={<NewLesson />} />}
        ></Route>
        <Route
          path={routes.LESSON_DETAIL_PATH}
          element={<AuthedLayout component={<LessonDetail />} />}
        ></Route>
        <Route path={routes.PLAN_PATH} element={<AuthedLayout component={<Plans />} />}></Route>
        <Route
          path={routes.PLAN_DETAIL_PATH}
          element={<AuthedLayout component={<PlanDetail />} />}
        ></Route>
        <Route
          path={routes.NEW_PLAN_PATH}
          element={<AuthedLayout component={<NewPlan />} />}
        ></Route>
        <Route
          path={routes.PLAN_EDIT_PATH}
          element={<AuthedLayout component={<EditPlan />} />}
        ></Route>
        <Route
          path={routes.LESSON_EDIT_PATH}
          element={<AuthedLayout component={<LessonEdit />} />}
        ></Route>
        <Route
          path={routes.NEW_LESSON_VIDEO}
          element={<AuthedLayout component={<NewLessonVideo />} />}
        ></Route>
        <Route
          path={routes.EDIT_LESSON_VIDEO}
          element={<AuthedLayout component={<EditLessonVideo />} />}
        ></Route>
        <Route
          path={routes.LESSON_VIDEO_DETAIL}
          element={<AuthedLayout component={<LessonVideoDetail />} />}
        ></Route>
        <Route
          path={routes.EDIT_INFLUENCER_PATH}
          element={<AuthedLayout component={<EditInfluencer />} />}
        ></Route>
        <Route
          path={routes.PROMOTION_PATH}
          element={<AuthedLayout component={<Promotions />} />}
        ></Route>
        <Route
          path={routes.NEW_PROMOTION_PATH}
          element={<AuthedLayout component={<NewPromotion />} />}
        ></Route>
        <Route
          path={routes.PROMOTION_DETAIL_PATH}
          element={<AuthedLayout component={<Promotion />} />}
        />
        <Route
          path={routes.PROMOTION_EDIT_PATH}
          element={<AuthedLayout component={<EditPromotion />} />}
        />
      </>
    </Routes>
  );
}
