import React, { useCallback, useMemo } from 'react';
import { PromotionCode } from '../../../../utils/type';
import { Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { patchPromotionCodeActivate } from '../../../../http/api/promotion';
import { toast } from 'react-toastify';
import { errorToast } from '../../../../utils/component/toast/style';

interface Props {
  promotionId: number;
  codes: PromotionCode[] | undefined;
}

interface TableDataType {
  key: React.Key;
  id: number;
  name: string;
  url: string;
  active: JSX.Element;
}

export function PromotionCodes({ promotionId, codes }: Props) {
  const columns: ColumnsType<TableDataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'コード名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: '公開',
      dataIndex: 'active',
      key: 'active',
    },
  ];

  const switchActivate = useCallback(
    async (value: boolean, codeId: number) => {
      const result = await patchPromotionCodeActivate(promotionId, codeId, value);
      if (!result) {
        toast.error('ステータスの切り替えに失敗しました', errorToast);
      } else {
        toast.success('ステータスの切り替えに成功しました');
      }
    },
    [promotionId]
  );

  const dataSource = useMemo(() => {
    const source: TableDataType[] = [];
    codes?.forEach((code, index) => {
      source.push({
        key: index,
        id: code.id,
        name: code.name,
        url: code.url,
        active: (
          <Switch
            defaultChecked={code.is_active}
            onChange={(value) => switchActivate(value, code.id)}
          ></Switch>
        ),
      });
    });
    return source;
  }, [codes, switchActivate]);
  return (
    <div>
      <h1>プロモーションコード一覧</h1>
      <Table
        columns={columns}
        dataSource={dataSource}
        style={{ width: 800, margin: '0 auto' }}
      ></Table>
    </div>
  );
}
