import { Button, Card, Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import ReactCrop from 'react-image-crop';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPlan, updatePlan } from '../../http/api/plan';
import { TrimmingModal } from '../../utils/component/cropper/TrimmingModal';
import { useImageSave } from '../../utils/component/cropper/useImageSave';
import { errorToast } from '../../utils/component/toast/style';
import { Plan } from '../../utils/type';
import './EditPlan.scss';
import { getCourses } from '../../http/api/course';

interface PlanUpdatePayload {
  name: string;
  description: string;
  stripe_price_id: string;
  price: number;
  label: string;
  main_image: File | string;
  basic_course_ids: number[];
}

export function EditPlan() {
  const [form] = Form.useForm<PlanUpdatePayload>();
  const [plan, setPlan] = useState<Plan>();
  const [basicCourseIds, setBasicCourseIds] = useState<string[]>();
  const location = useLocation();
  const navigate = useNavigate();
  const pathNameArray = location.pathname.split('/');
  const planId = pathNameArray[pathNameArray.length - 2];
  const [imgSrc, setImgSrc] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [saveCroppedImage] = useImageSave(setIsOpen, imgSrc);
  const imgRef = useRef<HTMLImageElement>(null);
  const [courses, setCourses] = useState<{ value: string; label: string }[]>([]);

  const finishCrop = (crop: PixelCrop, percentCrop: PercentCrop) => {
    if (!imgRef.current) {
      return;
    }
    const canvas = document.createElement('canvas');
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL('image/jpeg');

    setCroppedImage(base64Image);
  };

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || '');
          setIsOpen(true);
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const onFinish = async (values: PlanUpdatePayload) => {
    const name = values.name;
    const description = values.description;
    const stripe_price_id = values.stripe_price_id;
    const price = values.price;
    const label = values.label;
    const main_image = croppedImage ? croppedImage : plan?.main_image_url;
    const basic_course_ids = values.basic_course_ids;

    const result = await updatePlan(
      { items: { name, description, stripe_price_id, price, label, main_image, basic_course_ids } },
      parseInt(planId)
    );
    if (result) {
      toast.success('プランのアップデートに成功しました');
      navigate('/plan');
    } else {
      toast.error('プランのアップデートに失敗しました', errorToast);
    }
  };

  useEffect(() => {
    const fetchPlan = async () => {
      const result = await getPlan(parseInt(planId));
      if (result) {
        setPlan(result);
        const courseIds = result.basic_courses.map((c) => c.id.toString());
        setBasicCourseIds(courseIds);
      } else {
        toast.error('プランの取得に失敗しました', errorToast);
      }
    };
    fetchPlan();
  }, [planId]);

  useEffect(() => {
    const fetchCourses = async () => {
      const result = await getCourses();
      if (result) {
        let courseOptions: { value: string; label: string }[] = [];
        result.map((courseBase) =>
          courseOptions.push({ value: courseBase.id.toString(), label: courseBase.title })
        );

        setCourses(courseOptions);
      } else {
        toast.error('コースの取得に失敗しました', errorToast);
      }
    };
    fetchCourses();
  }, []);

  return (
    <div className='plan-edit'>
      <h1>プランの編集</h1>
      <Card className='plan-edit-card' loading={!plan || !courses}>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label='プラン名'
            name={'name'}
            initialValue={plan?.name}
            labelCol={{ span: 5 }}
          >
            <Input type='text' defaultValue={plan?.name} />
          </Form.Item>
          <Form.Item
            label='詳細情報'
            name={'description'}
            labelCol={{ span: 5 }}
            initialValue={plan?.description}
          >
            <TextArea defaultValue={plan?.description} />
          </Form.Item>
          <Form.Item
            label='stripe_price_id'
            name={'stripe_price_id'}
            initialValue={plan?.stripe_config.stripe_price_id}
            labelCol={{ span: 5 }}
          >
            <Input type='text' defaultValue={plan?.stripe_config.stripe_price_id} />
          </Form.Item>
          <Form.Item
            label='値段'
            name={'price'}
            initialValue={plan?.stripe_config.price}
            labelCol={{ span: 5 }}
          >
            <Input type='number' defaultValue={plan?.stripe_config.price} />
          </Form.Item>
          <Form.Item
            label='URLに入るラベル'
            name='label'
            initialValue={plan?.stripe_config.label}
            labelCol={{ span: 5 }}
          >
            <Input type='text' defaultValue={plan?.stripe_config.label} />
          </Form.Item>
          <Form.Item
            name={'basic_course_ids'}
            label='このプランで無料になる講座'
            labelCol={{ span: 6 }}
            initialValue={basicCourseIds}
          >
            <Select mode='multiple' options={courses} defaultValue={basicCourseIds}></Select>
          </Form.Item>
          <Form.Item label='プランのサムネイル' name='main_image' labelCol={{ span: 5 }}>
            <Input type='file' accept='image/*' onChange={onSelectFile}></Input>
          </Form.Item>
          <Form.Item label='画像のプレビュー' name='main_image' labelCol={{ span: 5 }}>
            <img src={croppedImage ? croppedImage : plan?.main_image_url} alt='' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              編集完了
            </Button>
          </Form.Item>
        </Form>
        <TrimmingModal isOpen={isOpen} setIsOpen={setIsOpen} saveCroppedImg={saveCroppedImage}>
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            aspect={9 / 5}
            onComplete={finishCrop}
          >
            <img src={imgSrc} ref={imgRef} alt='' />
          </ReactCrop>
        </TrimmingModal>
      </Card>
    </div>
  );
}
