import { Button, Modal } from 'antd';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  saveCroppedImg: () => Promise<void>;
  children: ReactNode;
}

export function TrimmingModal({ isOpen, setIsOpen, saveCroppedImg, children }: Props) {
  return (
    <Modal open={isOpen} onCancel={() => setIsOpen(false)} footer={null}>
      {children}
      <Button onClick={saveCroppedImg}>登録</Button>
    </Modal>
  );
}
