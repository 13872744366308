import { CourseBase, CourseDetail, Influencer, Lesson, StripeConfig } from '../../utils/type';
import {
  ACTIVE_COURSE,
  CLOSE_COURSE,
  CREATE_COURSE,
  GET_COURSE,
  GET_COURSES,
  UPDATE_COURSE,
} from '../endpoints';
import { getJson, patchJson, postJson, ReadResponse, RequestData } from '../network';

type CreateCourseRequest = RequestData<{
  title: string;
  description: string;
  stripe_price_id: File;
  thumb_image: File;
  price: number;
}>;

type CreateCourseResponse = ReadResponse<{
  id: number;
  title: string;
  description: string;
  token: string;
  thumb_image_url: string;
  stripe_config: StripeConfig;
  influencer: Influencer[];
  lessons: Lesson[];
}>;

export async function createCourse(data: CreateCourseRequest, influencerId: number) {
  const path = CREATE_COURSE(influencerId);
  const response = await postJson<CreateCourseResponse>(path, data);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type GetCoursesResponse = ReadResponse<CourseBase[]>;

export async function getCourses() {
  const path = GET_COURSES;
  const response = await getJson<GetCoursesResponse>(path);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type GetCourseResponse = ReadResponse<{
  id: number;
  title: string;
  description: string;
  token: string;
  thumb_image_url: string;
  stripe_config: StripeConfig;
  influencer: Influencer;
  lessons: Lesson[];
  is_opened: boolean;
}>;

export async function getCourse(courseId: number) {
  const path = GET_COURSE(courseId);
  const response = await getJson<GetCourseResponse>(path);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type UpdateCourseRequest = RequestData<{
  stripe_price_id: string;
  title: string;
  description: string;
  thumb_image: string | File;
  price: number;
}>;

type UpdateCourseResponse = ReadResponse<CourseDetail>;

export async function updateCourse(courseId: number, data: UpdateCourseRequest) {
  const path = UPDATE_COURSE(courseId);
  const response = await patchJson<UpdateCourseResponse>(path, data);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type OpenCourseResponse = ReadResponse<CourseDetail>;

export async function openCourse(courseId: number) {
  const path = ACTIVE_COURSE(courseId);
  const response = await postJson<OpenCourseResponse>(path);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type CloseCourseResponse = ReadResponse<CourseDetail>;

export async function closeCourse(courseId: number) {
  const path = CLOSE_COURSE(courseId);
  const response = await postJson<CloseCourseResponse>(path);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}
