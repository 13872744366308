import Cookies from 'js-cookie';
import { getAdminMe } from '../../http/api/admin';
import { refreshCsrfToken, setCsrfTokenForHeader } from '../../http/network';
import { getAdminUserState } from '../../store/adminUser/selectors';
import { setAdminUser } from '../../store/adminUser/slice';
import { useAppDispatch, useAppSelector } from '../../store/store';

export async function useHttpConfiguration() {
  const dispatch = useAppDispatch();
  const csrfToken = Cookies.get('csrfToken');
  const user = useAppSelector(getAdminUserState);
  if (!csrfToken) {
    const newToken = await refreshCsrfToken();
    if (!newToken) {
      throw new Error('error when fetch csrftoken');
    } else {
      Cookies.set('csrfToken', newToken);
      setCsrfTokenForHeader(newToken);
    }
  } else {
    setCsrfTokenForHeader(csrfToken);
  }
  if (!user.name) {
    const user = await getAdminMe();
    if (user) {
      dispatch(setAdminUser(user));
    }
  }
}
