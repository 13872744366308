import { ADMIN_SIGNIN, GET_ADMIN_ME } from '../endpoints';
import { getJson, postJson, ReadResponse, RequestData } from '../network';

type SignInRequest = RequestData<{
  email: string;
  password: string;
}>;

type AdminUserResponse = ReadResponse<{
  name: string;
  email: string;
}>;

export async function signIn(data: SignInRequest) {
  const response = await postJson<AdminUserResponse>(ADMIN_SIGNIN, data);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

export async function getAdminMe() {
  const path = GET_ADMIN_ME;
  const response = await getJson<AdminUserResponse>(path);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}
