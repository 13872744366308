import { Button, Form, Input } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createLesson } from '../../http/api/lesson';
import { errorToast } from '../../utils/component/toast/style';
import './NewLesson.scss';

interface LessonCreatePayload {
  title: string;
  description: string;
}
export function NewLesson() {
  const [form] = Form.useForm<LessonCreatePayload>();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const { title, description } = form.getFieldsValue(['title', 'description']);
    const pathname = location.pathname;
    const courseId = parseInt(pathname.split('/')[2]);

    const response = await createLesson(
      {
        items: { title: title, description: description },
      },
      courseId
    );
    if (response) {
      navigate(`/course/${courseId}`);
    } else {
      toast.error('授業の作成に失敗しました', errorToast);
    }
  };
  return (
    <div className='new-lesson'>
      <h1>授業追加</h1>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name='title'>
          <Input type='text' placeholder='授業名' />
        </Form.Item>
        <Form.Item name='description'>
          <Input type='text' placeholder='授業の説明' />
        </Form.Item>
        <Form.Item className='button-line'>
          <Button htmlType='submit' type='primary'>
            新規追加
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
