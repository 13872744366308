import { Button, Card, Form, Input } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateLesson } from '../../http/api/lesson';
import { errorToast } from '../../utils/component/toast/style';
import { Lesson } from '../../utils/type';

interface UpdateLessonFormPayload {
  title: string;
  description: string;
  order: number;
}

export function LessonEdit() {
  const [form] = Form.useForm<UpdateLessonFormPayload>();
  const location = useLocation();
  const navigate = useNavigate();

  const lesson: Lesson = location.state as Lesson;
  const onFinish = async (value: UpdateLessonFormPayload) => {
    const response = await updateLesson(
      { items: { title: value.title, description: value.description, order: value.order } },
      lesson.id
    );
    if (response) {
      toast.success('更新に成功しました');
      navigate(`/lesson/${lesson.id}`);
    } else {
      toast.error('更新に失敗しました', errorToast);
    }
  };
  return (
    <div className='lesson-edit'>
      <h1>レッスンの編集</h1>
      <Card>
        <Form form={form} onFinish={onFinish}>
          <Form.Item name='title' initialValue={lesson.title}>
            <Input type='text' defaultValue={lesson.title} />
          </Form.Item>
          <Form.Item name='description' initialValue={lesson.description}>
            <Input type='text' defaultValue={lesson.description} />
          </Form.Item>
          <Form.Item name='order' initialValue={lesson.order}>
            <Input type='number' defaultValue={lesson.order} />
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            完了
          </Button>
        </Form>
      </Card>
    </div>
  );
}
