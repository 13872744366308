import { Lesson, LessonVideo } from '../../utils/type';
import { CREATE_LESSON, GET_LESSON, UPDATE_LESSON } from '../endpoints';
import { getJson, patchJson, postJson, ReadResponse, RequestData } from '../network';

type CreateLessonRequest = RequestData<{
  title: string;
  description: string;
}>;

type CreateLessonResponse = ReadResponse<{
  id: number;
  title: string;
  description: string;
  order: number;
  lesson_videos: LessonVideo[];
}>;

export async function createLesson(data: CreateLessonRequest, courseId: number) {
  const path = CREATE_LESSON(courseId);
  const response = await postJson<CreateLessonResponse>(path, data);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type UpdateLessonRequest = RequestData<{
  title: string;
  description: string;
  order: number;
}>;

type UpdateLessonResponse = ReadResponse<Lesson>;

export async function updateLesson(data: UpdateLessonRequest, lessonId: number) {
  const path = UPDATE_LESSON(lessonId);
  const response = await patchJson<UpdateLessonResponse>(path, data);
  if (response.status === 'ok') {
    return true;
  } else {
    return false;
  }
}

type GetLessonResponse = ReadResponse<Lesson>;

export async function getLesson(lessonId: number) {
  const path = GET_LESSON(lessonId);
  const response = await getJson<GetLessonResponse>(path);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}
