import React, { useMemo } from 'react';
import { Plan } from '../../../utils/type';
import Table, { ColumnsType } from 'antd/es/table';
import { Switch } from 'antd';
import { switchCampaignActivate } from '../../../http/api/plan';
import { toast } from 'react-toastify';
import { errorToast } from '../../../utils/component/toast/style';

interface Props {
  plan: Plan | undefined;
}

interface TableDataType {
  key: React.Key;
  id: number;
  name: string;
  url: string;
  active: JSX.Element;
}

export function Campaigns({ plan }: Props) {
  const columns: ColumnsType<TableDataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'キャンペーン名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: '公開',
      dataIndex: 'active',
      key: 'active',
    },
  ];

  const switchActive = async (value: boolean, campaignId: number) => {
    const result = await switchCampaignActivate(campaignId, value);
    if (!result) {
      toast.error('ステータスの切り替えに失敗しました', errorToast);
    } else {
      toast.success('ステータスの切り替えに成功しました');
    }
  };

  const dataSourse = useMemo(() => {
    const source: TableDataType[] = [];
    plan?.campaigns?.forEach((campaign) => {
      source.push({
        key: campaign.id,
        id: campaign.id,
        name: campaign.name,
        url: campaign.url,
        active: (
          <Switch
            onChange={(value) => switchActive(value, campaign.id)}
            defaultChecked={campaign.is_active}
          ></Switch>
        ),
      });
    });
    return source;
  }, [plan]);

  return (
    <div className='campaigns'>
      <Table
        columns={columns}
        dataSource={dataSourse}
        style={{ width: 800, margin: '0 auto' }}
      ></Table>
    </div>
  );
}
