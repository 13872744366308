import { Button, Card, Form, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import ReactCrop from 'react-image-crop';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TrimmingModal } from '../../utils/component/cropper/TrimmingModal';
import { useImageSave } from '../../utils/component/cropper/useImageSave';
import { errorToast } from '../../utils/component/toast/style';
import { InfluencerDetail } from '../../utils/type';
import { getInfluencer, updateInfluencer } from '../../http/api/influencer';
import './EditInfluencer.scss';

export interface InfluencerUpdatePayload {
  name: string;
  icon_img: File;
  label: string;
}

export function EditInfluencer() {
  const [form] = Form.useForm<InfluencerUpdatePayload>();
  const [influencer, setInfluencer] = useState<InfluencerDetail>();
  const navigate = useNavigate();
  const params = useParams();
  const influencerId = params.influencerId ? params.influencerId : '0';
  const [imgSrc, setImgSrc] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [saveCroppedImage] = useImageSave(setIsOpen, imgSrc);
  const imgRef = useRef<HTMLImageElement>(null);

  const finishCrop = (crop: PixelCrop, percentCrop: PercentCrop) => {
    if (!imgRef.current) {
      return;
    }
    const canvas = document.createElement('canvas');
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL('image/jpeg');

    setCroppedImage(base64Image);
  };

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || '');
          setIsOpen(true);
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const onFinish = async (values: InfluencerUpdatePayload) => {
    const name = values.name;
    const label = values.label;
    const icon_img = croppedImage ? croppedImage : influencer?.icon_image_url;

    const result = await updateInfluencer(parseInt(influencerId), {
      items: { name: name, label: label, icon_image: icon_img },
    });
    if (result) {
      toast.success('インフルエンサーのアップデートに成功しました');
      navigate('/influencer');
    } else {
      toast.error('インフルエンサーのアップデートに失敗しました', errorToast);
    }
  };

  useEffect(() => {
    const fetchInfluencer = async () => {
      const result = await getInfluencer(parseInt(influencerId));
      if (result) {
        setInfluencer(result);
        setImgSrc(result.icon_image_url);
      } else {
        toast.error('インフルエンサーの取得に失敗しました', errorToast);
      }
    };
    fetchInfluencer();
  }, [influencerId]);

  return (
    <div className='influencer-edit'>
      <h1>プランの編集</h1>
      <Card className='influencer-edit-card' loading={!influencer}>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label='インフルエンサー名'
            name={'name'}
            initialValue={influencer?.name}
            labelCol={{ span: 5 }}
          >
            <Input type='text' defaultValue={influencer?.name} />
          </Form.Item>
          <Form.Item
            label='URLに入るラベル'
            name='label'
            initialValue={influencer?.label}
            labelCol={{ span: 5 }}
          >
            <Input type='text' defaultValue={influencer?.label} />
          </Form.Item>
          <Form.Item label='プランのサムネイル' name='main_image' labelCol={{ span: 5 }}>
            <Input type='file' accept='image/*' onChange={onSelectFile}></Input>
          </Form.Item>
          <Form.Item label='画像のプレビュー' name='main_image' labelCol={{ span: 5 }}>
            <img src={croppedImage ? croppedImage : influencer?.icon_image_url} alt='' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              編集完了
            </Button>
          </Form.Item>
        </Form>
        <TrimmingModal isOpen={isOpen} setIsOpen={setIsOpen} saveCroppedImg={saveCroppedImage}>
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            aspect={1}
            circularCrop={true}
            onComplete={finishCrop}
          >
            <img src={imgSrc} ref={imgRef} alt='' />
          </ReactCrop>
        </TrimmingModal>
      </Card>
    </div>
  );
}
