import React, { useState } from 'react';
import './App.css';
import { useHttpConfiguration } from './utils/hooks/useHttpConfiguration';
import { defaultOption } from './http/network';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './routes/Router';

function App() {
  console.log('render App....');

  const [initialized, setInitialized] = useState<boolean>(false);

  useHttpConfiguration().finally(() => {
    setInitialized(true);
  });

  if (defaultOption.headers && Object.keys(defaultOption.headers).length === 1) {
    return initialized ? <div>サーバーと接続できていません</div> : <></>;
  }

  return <BrowserRouter>{initialized ? <Router /> : <></>}</BrowserRouter>;
}

export default App;
