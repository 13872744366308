import { Plan, SubscriptionPlanCampaign } from '../../utils/type';
import {
  CREATE_PLAN,
  GET_PLANS,
  GET_PLAN_DETAIL,
  POST_ACTIVATE_CAMPAIGNS,
  POST_CREATE_CAMPAIGNS,
  POST_DEACTIVATE_CAMPAIGNS,
  UPDATE_PLAN,
} from '../endpoints';
import { getJson, patchJson, postJson, ReadResponse, RequestData } from '../network';

type GetPlansResponse = ReadResponse<Plan[]>;

export async function getPlans() {
  const response = await getJson<GetPlansResponse>(GET_PLANS);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type GetPlanResponse = ReadResponse<Plan>;

export async function getPlan(planId: number) {
  const response = await getJson<GetPlanResponse>(GET_PLAN_DETAIL(planId));
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type CreatePlanRequest = RequestData<{
  name: string;
  description: string;
  stripe_price_id: string;
  price: number;
  label: string;
  main_image: File;
}>;

type CreatePlanResponse = ReadResponse<Plan>;

export async function CreatePlan(data: CreatePlanRequest) {
  const response = await postJson<CreatePlanResponse>(CREATE_PLAN, data);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type UpdatePlanRequest = RequestData<{
  name: string;
  description: string;
  stripe_price_id: string;
  price: number;
  label: string;
  main_image: File | string;
  basic_course_ids: number[];
}>;

type UpdatePlanResponse = ReadResponse<Plan>;

export async function updatePlan(data: UpdatePlanRequest, planId: number) {
  const path = UPDATE_PLAN(planId);
  const response = await patchJson<UpdatePlanResponse>(path, data);
  if (response.status === 'ok') {
    return true;
  } else {
    return false;
  }
}

type CreatePlanCampaignRequest = RequestData<{
  name: string;
}>;

type CreatePlanCampaignResponse = ReadResponse<SubscriptionPlanCampaign>;

export async function createPlanCampaign(planId: number, data: CreatePlanCampaignRequest) {
  const path = POST_CREATE_CAMPAIGNS(planId);
  const response = await postJson<CreatePlanCampaignResponse>(path, data);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}

type SwitchCampaignResponse = ReadResponse<SubscriptionPlanCampaign>;

export async function switchCampaignActivate(campaignId: number, afterStatus: boolean) {
  const path = afterStatus
    ? POST_ACTIVATE_CAMPAIGNS(campaignId)
    : POST_DEACTIVATE_CAMPAIGNS(campaignId);
  const response = await postJson<SwitchCampaignResponse>(path);
  if (response.status === 'ok') {
    return response.data;
  } else {
    return false;
  }
}
