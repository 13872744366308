import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CourseBase, Lesson, PromotionCourse, PromotionDetail } from '../../../utils/type';
import { getPromotion, patchPromotion } from '../../../http/api/promotion';
import { toast } from 'react-toastify';
import { Button, Card, Form, Input, Select } from 'antd';
import { getCourse, getCourses } from '../../../http/api/course';
import { errorToast } from '../../../utils/component/toast/style';
import { EachCourseComponent } from './EachCourseCompornent';

export function EditPromotion() {
  const params = useParams();
  const [promotion, setPromotion] = useState<PromotionDetail>();
  const [courses, setCourses] = useState<CourseBase[]>();
  const promotionId = params.promotionId ? parseInt(params.promotionId) : 0;
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [form] = Form.useForm();
  const [promotionCourses, setPromotionCourses] = useState<PromotionCourse[] | undefined>();

  useEffect(() => {
    const fetchPromotion = async () => {
      const response = await getPromotion(promotionId);
      if (response) {
        console.log('レスポンス', response);
        setPromotion(response);
        console.log(response.promotion_courses);
        setPromotionCourses(
          response?.promotion_courses.map((pc) => ({
            thumb_image_url: pc.thumb_image_url,
            order: pc.order,
            course: pc.course,
          }))
        );
      } else {
        toast.error('プロモーションの取得に失敗しました');
      }
    };
    fetchPromotion();
  }, [promotionId]);

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await getCourses();
      if (!response) {
        toast.error('コース情報の取得に失敗しました');
        return;
      }
      setCourses(response);
    };
    fetchCourses();
  }, []);

  const changeCourse = async (value: number) => {
    const courseId = value;
    const response = await getCourse(courseId);
    if (!response) {
      toast.error('コースの取得に失敗しました', errorToast);
      return;
    }
    setLessons(response.lessons);
  };

  const onSubmit = async (values: any) => {
    const { name } = values;
    if (!promotionCourses) return;
    const promotion_courses = promotionCourses.map((pc) => ({
      course_id: pc.course.id,
      order: pc.order,
      thumb_image: pc.thumb_image_url,
    }));

    const response = await patchPromotion(promotionId, {
      items: { name: name, promotion_courses: promotion_courses },
    });
    if (!response) {
      toast.error('プロモーションの編集に失敗しました');
      return;
    }

    toast.success('プロモーションの編集に成功しました');
  };

  return (
    <div className='promotion-edit'>
      <h1>プロモーション編集</h1>
      <Card className='promotion' loading={!promotion || !courses || !promotionCourses}>
        <Form form={form} onFinish={onSubmit}>
          <Form.Item initialValue={promotion?.name} name={'name'}>
            <Input type='text' />
          </Form.Item>

          <div className='selected-course'>
            <p>選択されてるコース一覧</p>

            {promotionCourses &&
              promotionCourses.map((pc, index) => (
                <EachCourseComponent
                  setPromotionCourses={setPromotionCourses}
                  promotionCourse={pc}
                  key={index}
                />
              ))}
          </div>

          <Form.Item>
            <Select
              placeholder='コースを選択してください'
              options={courses?.map((course) => ({ label: course.title, value: course.id }))}
              onChange={(value) => changeCourse(value)}
            ></Select>
            <Select
              options={lessons.map((lesson) => ({ label: lesson.title, value: lesson.id }))}
              onSelect={(value) =>
                setPromotionCourses((prevState) => {
                  const lesson = lessons.find((lesson) => lesson.id === value);
                  if (!lesson) return prevState;
                  if (prevState) {
                    const selectedCourseIds = prevState.map((pc) => pc.course.id);
                    if (selectedCourseIds.includes(lesson.course_id)) {
                      return prevState.map((pc) =>
                        pc.course.id === lesson.course_id
                          ? {
                              thumb_image_url: undefined,
                              course: courses?.find((course) => course.id === lesson.course_id)!,
                              order: lesson.order,
                            }
                          : pc
                      );
                    } else {
                      return [
                        ...prevState,
                        {
                          thumb_image_url: undefined,
                          course: courses?.find((course) => course.id === lesson.course_id)!,
                          order: lesson.order,
                        },
                      ];
                    }
                  }
                })
              }
            />
          </Form.Item>

          <Form.Item>
            <Button htmlType='submit' type='primary'>
              編集する
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
