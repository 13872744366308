import { Layout } from 'antd';
import Cookies from 'js-cookie';
import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HOME_PATH } from './routes';
import './UnAuthedLayout.scss';

interface Props {
  component: ReactNode;
}

export function UnAuthedLayout({ component }: Props) {
  const userToken = Cookies.get('userToken');
  const navigate = useNavigate();

  useEffect(() => {
    if (userToken) {
      navigate(HOME_PATH);
    }
  }, [navigate, userToken]);

  return (
    <Layout className='unauthed-layout'>
      <Layout.Header className='header'></Layout.Header>
      <Layout.Content>{component}</Layout.Content>
      <Layout.Footer></Layout.Footer>
    </Layout>
  );
}
