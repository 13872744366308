import { Button, Form, Input } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { creaetLessonVideo } from '../../http/api/lessonVideo';
import './NewLessonVideos.scss';

interface CreateLessonVideoPayload {
  vimeo_id: string;
  title: string;
  description: string;
}

export function NewLessonVideo() {
  const navigate = useNavigate();
  const params = useParams();
  const lessonId = params.lessonId;
  const [form] = Form.useForm<CreateLessonVideoPayload>();
  const onSubmit = async (value: CreateLessonVideoPayload) => {
    if (!lessonId) return;
    const response = await creaetLessonVideo(parseInt(lessonId), { items: value });
    if (response) {
      toast.success('動画を作成しました');
      navigate(`/lesson/${lessonId}`);
    } else {
      toast.error('動画の作成に失敗しました');
    }
  };

  return (
    <div className='new-lesson-video'>
      <h1>授業動画の新規追加</h1>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name='title'>
          <Input type='text' placeholder='タイトル' />
        </Form.Item>
        <Form.Item name='description'>
          <Input type='text' placeholder='授業動画の詳細' />
        </Form.Item>
        <Form.Item name='vimeo_id'>
          <Input type='text' placeholder='動画のURL' />
        </Form.Item>
        <Button type='primary' htmlType='submit'>
          登録
        </Button>
      </Form>
    </div>
  );
}
