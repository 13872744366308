import { Button, Form, Input, Select } from 'antd';
import './AddCourse.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import { useImageSave } from '../../utils/component/cropper/useImageSave';
import { TrimmingModal } from '../../utils/component/cropper/TrimmingModal';
import ReactCrop from 'react-image-crop';
import { InfluencerBase } from '../../utils/type';
import { getInfluencers } from '../../http/api/influencer';
import { toast } from 'react-toastify';
import { errorToast } from '../../utils/component/toast/style';
import { Option } from 'antd/es/mentions';
import { createCourse } from '../../http/api/course';
import { useNavigate } from 'react-router-dom';
import { COURSE_PATH } from '../../routes/routes';

export interface CourseCreationPayload {
  title: string;
  description: string;
  stripe_price_id: string;
  price: number;
  influencerId: number;
  thumb_image: File;
}

export function AddCourse() {
  const [form] = Form.useForm<CourseCreationPayload>();
  const [imgSrc, setImgSrc] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [saveCroppedImage] = useImageSave(setIsOpen, imgSrc);
  const imgRef = useRef<HTMLImageElement>(null);
  const [influencers, setInfluencers] = useState<InfluencerBase[]>([]);
  const navigate = useNavigate();

  const submitCourse = async () => {
    const { title, description, stripe_price_id, influencerId, price } = form.getFieldsValue([
      'title',
      'description',
      'stripe_price_id',
      'influencerId',
      'price',
    ]);
    const thumb_image = croppedImage;
    const numberdInfluencerId = parseInt(influencerId);
    const result = await createCourse(
      {
        items: {
          title: title,
          description: description,
          stripe_price_id: stripe_price_id,
          thumb_image: thumb_image,
          price: price,
        },
      },
      numberdInfluencerId
    );
    if (result) {
      navigate(COURSE_PATH);
    } else {
      toast.error('コースの作成に失敗しました', errorToast);
    }
  };

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || '');
          setIsOpen(true);
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const finishCrop = (crop: PixelCrop, percentCrop: PercentCrop) => {
    if (!imgRef.current) {
      return;
    }
    const canvas = document.createElement('canvas');
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL('image/jpeg');

    setCroppedImage(base64Image);
  };

  useEffect(() => {
    const fetchInfluencers = async () => {
      const result = await getInfluencers();
      if (result) {
        setInfluencers(result);
      } else {
        toast.error('インフルエンサーの取得に失敗しました', errorToast);
      }
    };

    fetchInfluencers();
  }, []);

  return (
    <div className='new-course'>
      <h1>講座の新規追加</h1>
      <Form form={form} onFinish={submitCourse}>
        <Form.Item name='title'>
          <Input type='text' placeholder='講座名' />
        </Form.Item>
        <Form.Item name='description'>
          <Input type='textarea' placeholder='講座の詳細情報' />
        </Form.Item>
        <Form.Item name='stripe_price_id'>
          <Input type='text' placeholder='stripeのpriceId（必ずコピペ！）' />
        </Form.Item>
        <Form.Item name='influencerId'>
          <Select placeholder='インフルエンサー名'>
            {influencers.map((influencer) => {
              return <Option value={influencer.id.toString()}>{influencer.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item name='price'>
          <Input type='number' placeholder='講座の値段' />
        </Form.Item>
        <Form.Item name='thumb_image'>
          <Input type='file' accept='image/*' onChange={onSelectFile}></Input>
        </Form.Item>
        <Form.Item className='button-line'>
          <Button type='primary' htmlType='submit'>
            新規作成
          </Button>
        </Form.Item>
        <img src={croppedImage} alt='' />
        {/* 担当インフルエンサーを選ぶinputが必要 */}
      </Form>
      <TrimmingModal isOpen={isOpen} setIsOpen={setIsOpen} saveCroppedImg={saveCroppedImage}>
        <ReactCrop crop={crop} onChange={(c) => setCrop(c)} aspect={9 / 5} onComplete={finishCrop}>
          <img src={imgSrc} ref={imgRef} alt='' />
        </ReactCrop>
      </TrimmingModal>
    </div>
  );
}
