import Table, { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { getUsers } from '../../http/api/user';
import { UserBase } from '../../utils/type';

interface TableDataType {
  key: React.Key;
  id: number;
  name: string;
  email: string;
  isSubscriber: string;
  isInfluencer: string;
  createdAt: string;
}

export function User() {
  const [users, setUsers] = useState<UserBase[]>();
  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getUsers();
      if (!response) {
        toast.error('ユーザーの取得に失敗しました');
        return;
      }
      setUsers(response);
    };
    fetchUsers();
  }, []);

  const columns: ColumnsType<TableDataType> = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'アドレス', dataIndex: 'email', key: 'email' },
    { title: '名前', dataIndex: 'name', key: 'name' },
    { title: 'サブスク', dataIndex: 'isSubscriber', key: 'isSubscriber' },
    { title: 'インフルエンサー', dataIndex: 'isInfluencer', key: 'isInfluencer' },
    { title: '作成日', dataIndex: 'createdAt', key: 'createdAt' },
  ];

  const dataSource = useMemo(() => {
    const source: TableDataType[] = [];
    users?.forEach((user) => {
      source.push({
        key: user.id,
        id: user.id,
        name: user.name,
        email: user.email,
        isSubscriber: user.is_subscriber ? '有料会員' : '無料',
        isInfluencer: user.is_influencer ? 'インフルエンサー' : '一般',
        createdAt: dayjs(user.created_at).format('YYYY年MM月DD日'),
      });
    });
    return source;
  }, [users]);

  return (
    <div>
      <h1>ユーザー管理</h1>
      <Table columns={columns} dataSource={dataSource} style={{ width: 800, margin: '0 auto' }} />
    </div>
  );
}
