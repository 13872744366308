import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { PromotionCourse } from '../../../utils/type';
import { Form, Input } from 'antd';
import { TrimmingModal } from '../../../utils/component/cropper/TrimmingModal';
import ReactCrop, { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import { useImageSave } from '../../../utils/component/cropper/useImageSave';

export type CourseImages = { image: string | File; courseId: number }[];
interface Props {
  setPromotionCourses: Dispatch<SetStateAction<PromotionCourse[] | undefined>>;
  promotionCourse: PromotionCourse;
  key: number;
}

export function EachCourseComponent({ setPromotionCourses, promotionCourse, key }: Props) {
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [imgSrc, setImgSrc] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const [saveCroppedImage] = useImageSave(setIsOpen, imgSrc);

  const initialCourseImage = promotionCourse.thumb_image_url;

  const [croppedImage, setCroppedImage] = useState<any>(initialCourseImage);
  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || '');
          setIsOpen(true);
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const finishCrop = (crop: PixelCrop, percentCrop: PercentCrop, courseId: number) => {
    if (!imgRef.current) {
      return;
    }
    const canvas = document.createElement('canvas');
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL('image/jpeg');

    setCroppedImage(base64Image);
    setPromotionCourses((prevState) => {
      if (prevState) {
        if (prevState.map((pc) => pc.course.id).includes(courseId)) {
          return prevState.map((pc) => {
            if (pc.course.id === courseId) {
              return {
                thumb_image_url: base64Image,
                course: promotionCourse.course,
                order: promotionCourse.order,
              };
            } else {
              return pc;
            }
          });
        } else {
          return [
            ...prevState,
            {
              thumb_image_url: base64Image,
              course: promotionCourse.course,
              order: promotionCourse.order,
            },
          ];
        }
      } else {
        return [
          {
            thumb_image_url: base64Image,
            course: promotionCourse.course,
            order: promotionCourse.order,
          },
        ];
      }
    });
  };

  return (
    <>
      <p>
        {promotionCourse.course.title}の第{promotionCourse.order}回までを無料開講
      </p>
      <img src={croppedImage} alt='' />
      <Form.Item label='サムネイル' labelCol={{ span: 5 }}>
        <Input type='file' accept='image/*' onChange={onSelectFile}></Input>
      </Form.Item>
      <TrimmingModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        saveCroppedImg={saveCroppedImage}
        key={key}
      >
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          aspect={9 / 5}
          onComplete={(crop, percentCrop) =>
            finishCrop(crop, percentCrop, promotionCourse.course.id)
          }
        >
          <img src={imgSrc} ref={imgRef} alt='' />
        </ReactCrop>
      </TrimmingModal>
    </>
  );
}
