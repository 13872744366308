import { Layout, Menu, MenuProps } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { USER_SIGNIN_PATH } from './routes';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { BsFillMegaphoneFill } from 'react-icons/bs';
import { AiFillHome } from 'react-icons/ai';
import './AuthedLayout.scss';
import { MenuInfo } from 'rc-menu/lib/interface';
import { isSignedIn } from '../store/adminUser/selectors';
import { useAppSelector } from '../store/store';

interface Props {
  component: ReactNode;
}

export function AuthedLayout({ component }: Props) {
  const navigate = useNavigate();
  const authed = useAppSelector(isSignedIn);

  const items: MenuProps['items'] = [
    { key: 'home', icon: React.createElement(AiFillHome), label: 'ホーム' },
    { key: 'user', icon: React.createElement(UserOutlined), label: 'ユーザー' },
    { key: 'course', icon: React.createElement(LaptopOutlined), label: 'コース' },
    { key: 'inquire', icon: React.createElement(NotificationOutlined), label: 'お問い合せ' },
    { key: 'influencer', icon: React.createElement(UserOutlined), label: 'インフルエンサー' },
    { key: 'plan', icon: React.createElement(LaptopOutlined), label: 'プラン' },
    { key: 'promotions', icon: React.createElement(BsFillMegaphoneFill), label: 'プロモーション' },
  ];

  const handleNavigationClick = (e: MenuInfo) => {
    navigate(`/${e.key}`);
  };

  useEffect(() => {
    if (!authed) {
      navigate(USER_SIGNIN_PATH);
    }
  }, [navigate, authed]);

  return (
    <Layout className='authed-layout'>
      <Header className='header'></Header>
      <Layout>
        <Sider className='sider'>
          <Menu
            mode='inline'
            style={{ height: '100%' }}
            items={items}
            onClick={(e) => handleNavigationClick(e)}
          />
        </Sider>
        <Content className='content'>{component}</Content>
      </Layout>
    </Layout>
  );
}
