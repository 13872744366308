import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PromotionBase } from '../../../utils/type';
import { getPromotions } from '../../../http/api/promotion';
import { toast } from 'react-toastify';
import Table, { ColumnsType } from 'antd/es/table';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { NEW_PROMOTION_PATH } from '../../../routes/routes';
import './Promotions.scss';

interface TableDataType {
  key: React.Key;
  id: number;
  name: string;
  show: JSX.Element;
  edit: JSX.Element;
}
export function Promotions() {
  const navigate = useNavigate();
  const [promotions, setPromotions] = useState<PromotionBase[]>();

  useEffect(() => {
    const fetchPromotions = async () => {
      const response = await getPromotions();
      if (!response) {
        toast.error('プロモーションの取得に失敗しました');
        return;
      }
      setPromotions(response);
    };
    fetchPromotions();
  }, []);

  const columns: ColumnsType<TableDataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '名前',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '詳細',
      dataIndex: 'show',
      key: 'show',
    },
    {
      title: '編集',
      dataIndex: 'edit',
      key: 'edit',
    },
  ];

  const handleShowButton = useCallback(
    (id: number) => {
      navigate(`/promotion/${id}`);
    },
    [navigate]
  );

  const handleEditButton = useCallback(
    (id: number) => {
      navigate(`/promotion/${id}/edit`);
    },
    [navigate]
  );

  const handleAddButton = () => {
    navigate(NEW_PROMOTION_PATH);
  };

  const dataSourse = useMemo(() => {
    const source: TableDataType[] = [];
    promotions?.forEach((p) => {
      source.push({
        key: p.id,
        id: p.id,
        name: p.name,
        show: <Button onClick={() => handleShowButton(p.id)}>詳細</Button>,
        edit: <Button onClick={() => handleEditButton(p.id)}>編集</Button>,
      });
    });
    return source;
  }, [promotions, handleEditButton, handleShowButton]);
  return (
    <div className='promotions'>
      <h1>プロモーション管理</h1>
      <div className='button-line'>
        <Button onClick={handleAddButton}>新規追加</Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSourse}
        style={{ width: 800, margin: '0 auto' }}
      ></Table>
    </div>
  );
}
